.app-logo-container {
  /* margin: 40px 176px 20px 176px; */
  height: 150px;
  text-align: center;
}

.app-logo {
  width: 96px;
  height: 96px;
}

.app-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  text-align: center;
  max-width: 320px;
}

.login-section-break {
  margin: 0 33px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.login-input-container {
  margin: 0 56px;
  margin-top: 60px;
  margin-bottom: 30px;
}

.login-btn {
  max-width: unset;
}

.log-out {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.request-account-section {
  height: 56px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.54);
}

@media (min-width: 717px) {
  .logincontainer {
    width: 446px;
    min-height: 523px;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
  }
}

@media (max-width: 717px) {
  .logincontainer {
    width: 100%;
    height: 100%;
  }
  .login-input-container {
    margin: 0 30px;
    margin-top: 60px;
    margin-bottom: 30px;
  }
}
